
import { formatCurrencyFloat } from "@/plugins/filters";
import { get } from "lodash";
import Vue from "vue";

export default Vue.extend({
  name: "PolicyDeductibles",
  props: {
    policyInquiryData: {
      type: Object
    }
  },
  computed: {
    policyType(): string {
      return get(this.policyInquiryData, "PolicyType", "");
    },
    deductibleClause1Amount(): string {
      return formatCurrencyFloat(get(this.policyInquiryData, "D1"));
    },
    deductibleClause2Amount(): string {
      return formatCurrencyFloat(get(this.policyInquiryData, "D2"));
    },
    deductibleClause3Amount(): string {
      return formatCurrencyFloat(get(this.policyInquiryData, "D3"));
    },
    deductibleClause1PremiumAmount(): string {
      return formatCurrencyFloat(get(this.policyInquiryData, "D1Amt"));
    },
    deductibleClause2PremiumAmount(): string {
      return formatCurrencyFloat(get(this.policyInquiryData, "D2Amt"));
    },
    deductibleClause3PremiumAmount(): string {
      return formatCurrencyFloat(get(this.policyInquiryData, "D3Amt"));
    },
    branch(): string {
      return get(this.policyInquiryData, "Branch", "");
    },
    getDwellingFirePremiumsTitles(): Record<string, string> {
      return {
        "FL[Amt1]": "DESCRIBED DWELLING FIRE AND LIGHTNING",
        "EC[Amt1]":
          "SUDDEN AND ACCIDENTAL DAMAGE FROM SMOKE, WINDSTORM, HURRICANE, HAIL, EXPLOSION, AIRCRAFT AND VEHICLES,  RIOT AND CIVIL COMMOTION.",
        "VMM[Amt1]": "VANDALISM AND  MALICIOUS  MISCHIEF",
        "AR[Amt1]":
          "ALL RISK OF PHYSICAL LOSS EXCEPT LOSSES EXCLUDED IN GENERAL EXCLUSIONS",
        "AEC[Amt1]":
          "COLLAPSE OF BUILDING, ACCIDENTAL DISCHARGE OF WATER OR STEAM, FREEZING, BREAKAGE OF GLASS, FALLING OBJECTS, VANDALISM AND MALICIOUS MISCHIEF",
        "FL[Amt2]": "PERSONAL PROPERTY FIRE AND LIGHTNING",
        "EC[Amt2]":
          "SUDDEN AND ACCIDENTAL DAMAGE FROM SMOKE, WINDSTORM, HURRICANE, HAIL, EXPLOSION, AIRCRAFT AND VEHICLES, RIOT AND CIVIL COMMOTION",
        "AEC[Amt2]":
          "COLLAPSE OF BUILDING, ACCIDENTAL DISCHARGE OF WATER OR STEAM, FREEZING, BREAKAGE OF GLASS, FALLING OBJECTS, VANDALISM AND MALICIOUS MISCHIEF",
        "VMM[Amt2]": "VANDALISM AND  MALICIOUS  MISCHIEF"
      };
    }
  },
  methods: {
    getDwellingFirePremiumValue(key: string) {
      return formatCurrencyFloat(get(this.policyInquiryData, key, "0"));
    }
  }
});
