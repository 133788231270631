
import { get } from "lodash";
import Vue from "vue";

export default Vue.extend({
  name: "PolicyDiscountDescription",
  props: {
    policyInquiryData: {
      type: Object
    }
  },
  computed: {
    getDiscountTitleWithValue(): Record<string, any> {
      const data = get(this.policyInquiryData, "disc");
      if (data && Array.isArray(data)) {
        return Object.fromEntries(data);
      }
      return {};
    }
  }
});
