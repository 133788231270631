
import { formatCurrencyFloat } from "@/plugins/filters";
import { get } from "lodash";
import Vue from "vue";

export default Vue.extend({
  name: "PropertySchedulePremium",
  props: {
    policyInquiryData: {
      type: Object
    }
  },
  computed: {
    PropSchedPrem() {
      return formatCurrencyFloat(
        get(this.policyInquiryData, "PropSchedPrem", "0")
      );
    },
    PropSchedTotal() {
      return formatCurrencyFloat(
        get(this.policyInquiryData, "PropSchedTotal", "0")
      );
    }
  }
});
