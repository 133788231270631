
import { get } from "lodash";
import Vue from "vue";

export default Vue.extend({
  name: "PolicyTotal",
  props: {
    policyInquiryData: {
      type: Object
    }
  },
  computed: {
    hasAccountNum1Id(): boolean {
      return Boolean(get(this.policyInquiryData, "accNumId", ""));
    },
    hasAccountNum2Id(): boolean {
      return Boolean(get(this.policyInquiryData, "accNumId2", ""));
    },
    accountNum1Id(): string {
      return get(this.policyInquiryData, "accNumId", "");
    },
    accountNum2Id(): string {
      return get(this.policyInquiryData, "accNumId2", "");
    },
    firstMortgageName(): string {
      return get(this.policyInquiryData, "Mortgage1Name", "");
    },
    firstMortgageAddress(): string {
      return get(this.policyInquiryData, "Mort1Addr", "");
    },
    firstMortgageCityStateZipCode(): string {
      return get(this.policyInquiryData, "Mortgage1CityStateZip", "");
    },
    secondMortgageName(): string {
      return get(this.policyInquiryData, "Mortgage2Name", "");
    },
    secondMortgageAddress(): string {
      return get(this.policyInquiryData, "Mort2Addr", "");
    },
    secondMortgageCityStateZipCode(): string {
      return get(this.policyInquiryData, "Mortgage2CityStateZip", "");
    }
  }
});
