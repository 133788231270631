
import { formatCurrencyFloat } from "@/plugins/filters";
import { get } from "lodash";
import Vue from "vue";

export default Vue.extend({
  name: "PolicyCoverage",
  props: {
    data: {
      type: Object
    }
  },
  computed: {
    policyType(): string {
      return get(this.data, "PolicyType", "");
    },
    hasICL(): boolean {
      return Boolean(get(this.data, "hasICL"));
    },
    policyIsHomeOwner(): boolean {
      return Boolean(this.policyType === "HO");
    },
    coverageDWGAmount(): string {
      return formatCurrencyFloat(get(this.data, "DWG"));
    },
    coverageOTSAmount(): string {
      return formatCurrencyFloat(get(this.data, "OTS"));
    },
    coverageUPPAmount(): string {
      return this.policyType !== "PL"
        ? formatCurrencyFloat(get(this.data, "UPP"))
        : "";
    },
    coverageUPP_TenthAmount(): string {
      return this.policyType !== "PL"
        ? formatCurrencyFloat(get(this.data, "UPP", 0) / 10)
        : "";
    },
    coverageICLAmount(): string {
      return this.policyType !== "PL"
        ? formatCurrencyFloat(get(this.data, "ICL"))
        : formatCurrencyFloat(get(this.data, "UPP", 0));
    },
    coverageMPOAmount(): string {
      return formatCurrencyFloat(get(this.data, "MPO"));
    },
    coverageALEAmount(): string {
      return formatCurrencyFloat(get(this.data, "ALE"));
    },
    coverageBasicAmount() {
      return "-";
    },
    hasICLCoverageAmount() {
      return "-";
    },
    premiumDWGAmt(): string {
      return formatCurrencyFloat(get(this.data, "DWGAmt"));
    },
    premiumOTSAmt(): string {
      return formatCurrencyFloat(get(this.data, "OTSAmt"));
    },
    premiumUPPAmt(): string {
      return formatCurrencyFloat(get(this.data, "UPPAmt"));
    },
    premiumUpp_TenthAmt(): string {
      return "INC.";
    },
    premiumICLAmt(): string {
      return this.policyType !== "PL"
        ? "-"
        : formatCurrencyFloat(get(this.data, "UPPAmt", 0));
    },
    premiumMPOAmt() {
      return "INC.";
    },
    premiumALEAmt() {
      return "INC.";
    },
    basicPremium(): string {
      return get(this.data, "basicPremiumForUI");
    },
    hasICLPreAmt(): string {
      return formatCurrencyFloat(get(this.data, "ICLAmt"));
    },
    dwellingD1Value(): string {
      return get(this.data, "D1");
    },
    dwellingFLValue(): string {
      return formatCurrencyFloat(get(this.data, "FL.limit1"));
    },
    dwellingD2Value(): string {
      return formatCurrencyFloat(get(this.data, "D2", "0"));
    },
    dwellingFL2Value(): string {
      return formatCurrencyFloat(get(this.data, "FL.limit2", "0"));
    }
  }
});
