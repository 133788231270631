
import { formatCurrencyFloat } from "@/plugins/filters";
import { get } from "lodash";
import Vue from "vue";

export default Vue.extend({
  name: "PolicyEndorsementCoverage",
  props: {
    policyInquiryData: {
      type: Object
    }
  },
  methods: {
    endorsementAndDescription(type: string): any[] {
      let title: any = [];
      let limits: any = [];
      let premium: any = [];
      let endorLst = get(this.policyInquiryData, "endorLst", []);
      let endobje = get(this.policyInquiryData, "Endor");
      let endor: any[] = [];

      if (endobje && Object.keys(endobje).length > 0) {
        endor = Object.keys(endobje!).map((key: any) => [key, endobje![key]]);
      }

      if (endor.length && Array.isArray(endor)) {
        for (let [key, value] of endor) {
          title.push(endorLst[key]);
          if (key == "255" || key == "215") {
            limits.push(value[1] ? value[1] : "-");
          } else if (key == "022" || key == "145") {
            let data = "&nbsp;";
            if (value[1] != 0) {
              data = `${
                formatCurrencyFloat(value[1])
                  ? "Class" + `${value[2]} ${value[1]} %`
                  : "-"
              }`;
            }
            limits.push(data);
          } else if (key == "135" || key == "026" || key == "027") {
            limits.push(value[1] ? `${value[1]} %` : "-");
          } else if (key == "017 RENT 1" || key == "018 RENT 2") {
            limits.push(
              `${
                formatCurrencyFloat(value[1])
                  ? "Class" + value[2] + "-" + value[1]
                  : "-"
              }`
            );
          } else {
            limits.push(`${value[1] ? formatCurrencyFloat(value[1]) : "-"}`);
          }
          premium.push(`${value[0] ? formatCurrencyFloat(value[0]) : "$0.00"}`);
        }
      }

      if (get(this.policyInquiryData, "PropSchedPrem")) {
        title.push("HO-160A Schedule Personal Property");
        limits.push(
          `${
            get(this.policyInquiryData, "PropSchedTotal")
              ? formatCurrencyFloat(
                  get(this.policyInquiryData, "PropSchedTotal")
                )
              : "-"
          }`
        );
        premium.push(
          `${formatCurrencyFloat(get(this.policyInquiryData, "PropSchedPrem"))}`
        );
      }
      title.push("Premium from All Endorsements");
      limits.push("-");
      premium.push(
        `${formatCurrencyFloat(get(this.policyInquiryData, "End_Tot"))}`
      );
      if (type == "title") {
        return title;
      } else if (type == "limits") {
        return limits;
      } else {
        return premium;
      }
    }
  },
  computed: {
    endorsementTitle(): string[] {
      return this.endorsementAndDescription("title");
    },
    endorsementLimits(): string[] {
      return this.endorsementAndDescription("limits");
    },
    endorsementPremiums(): string[] {
      return this.endorsementAndDescription("premiums");
    }
  }
});
