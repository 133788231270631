import {
  useFormSection,
  useFormFieldGroup,
  useField
} from "@/components/FormBuilder/Helpers";
import { FormBlock } from "@/components/FormBuilder/types";

export function policyInquiryForm(): FormBlock[] {
  const policyFormBlock = [
    ...useFormSection([
      ...useFormFieldGroup(
        [
          useField({
            key: "insuredName",
            type: "dataview",
            label: "Insured Name",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "insuredName",
                  value: true
                }
              ]
            }
          }),
          useField({
            key: "mailAddress",
            type: "dataview",
            label: "Mail Address",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "mailAddress",
                  value: true
                }
              ]
            }
          }),
          useField({
            key: "cityStateZip",
            type: "dataview",
            label: "City / State / Zip",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "cityStateZip",
                  value: true
                }
              ]
            }
          }),
          useField({
            key: "policyNumber",
            type: "dataview",
            label: "Policy Number",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "policyNumber",
                  value: true
                }
              ]
            }
          })
        ],
        { layout: "4-col" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "effectiveDate",
            type: "dataview",
            label: "Effective Date",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "effectiveDate",
                  value: true
                }
              ]
            }
          }),
          useField({
            key: "agencyName",
            type: "dataview",
            label: "Agency Name",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "agencyName",
                  value: true
                }
              ]
            }
          }),
          useField({
            key: "agentCode",
            type: "dataview",
            label: "Agent Code",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "agentCode",
                  value: true
                }
              ]
            }
          }),
          useField({
            key: "fPhoneNumber",
            type: "dataview",
            label: "Phone Number",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "fPhoneNumber",
                  value: true
                }
              ]
            }
          })
        ],
        { layout: "4-col" }
      )
    ]),
    ...useFormSection(
      [
        ...useFormFieldGroup(
          [
            useField({
              key: "locationAddress",
              type: "dataview",
              label: "Location Address",
              conditions: {
                and: [
                  {
                    operator: "exists",
                    field: "locationAddress",
                    value: true
                  }
                ]
              }
            }),
            useField({
              key: "locationCityStateZip",
              type: "dataview",
              label: "Location City / State / Zip",
              conditions: {
                and: [
                  {
                    operator: "exists",
                    field: "locationCityStateZip",
                    value: true
                  }
                ]
              }
            })
          ],
          { layout: "4-col" }
        )
      ],
      {
        title: "Location Address"
      }
    ),
    ...useFormSection(
      [
        ...useFormFieldGroup(
          [
            useField({
              key: "texasPolicyFrom",
              type: "dataview",
              label: "Texas Policy Form",
              conditions: {
                and: [
                  {
                    operator: "exists",
                    field: "texasPolicyFrom",
                    value: true
                  }
                ]
              }
            }),
            useField({
              key: "Construction",
              type: "dataview",
              label: "Construction",
              conditions: {
                and: [
                  {
                    operator: "exists",
                    field: "Construction",
                    value: true
                  }
                ]
              }
            })
          ],
          { layout: "4-col" }
        )
      ],
      {
        title: "Rating Information"
      }
    )
  ];
  return policyFormBlock;
}
