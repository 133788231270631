
import { formatCurrencyFloat } from "@/plugins/filters";
import Vue from "vue";

export default Vue.extend({
  name: "ScheduledProperty",
  props: {
    policyInquiryData: {
      type: Object
    }
  },

  computed: {
    formScheduleProperty(): any {
      const { PropSchedCat, PropSched } = this.policyInquiryData;

      if (!Array.isArray(PropSchedCat) || !Array.isArray(PropSched)) return {};

      return PropSchedCat.reduce((result, category) => {
        const filteredProps = PropSched.filter(
          item => item.Category === category
        );

        if (filteredProps.length === 0) return result;

        result[category] = {
          item: filteredProps.map(item => item.ItemDesc),
          date: filteredProps.map(item => item.EffectiveDt),
          value: filteredProps.map(item => formatCurrencyFloat(item.Amt))
        };

        return result;
      }, {});
    }
  }
});
