
import { formatCurrencyFloat } from "@/plugins/filters";
import { get } from "lodash";
import Vue from "vue";

export default Vue.extend({
  name: "PolicyTotal",
  props: {
    policyInquiryData: {
      type: Object
    }
  },
  computed: {
    getTotalPolicyFee(): number {
      return parseFloat(get(this.policyInquiryData, "PolicyFee"));
    },
    policyFeeFormatted(): string {
      return formatCurrencyFloat(this.getTotalPolicyFee);
    },
    getPolicyTotal(): string {
      return get(this.policyInquiryData, "policyTotalForUI");
    }
  }
});
